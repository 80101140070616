import { Donation } from '@wix/ambassador-donations-api/types';
import { fetchDonations } from '../../services/dontations-service';
import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const displayFeed = (donations: Donation[] | undefined) => {
    if (!donations) {
      return;
    }

    const repeater = $w('#repeater1');

    repeater.onItemReady(($item, itemData: Donation) => {
      $item('#contributorName').text = itemData.contributorName || 'Donator';
      $item(
        '#contributionAmount',
      ).text = `${itemData.payment?.amount} ${itemData.payment?.currency}`;
      $item('#contributionDate').text = itemData.createdDate;
      $item('#contributorComment').text = itemData.contributorComment;

      if (itemData.ownerComment) {
        $item('#ownerContainer').show();
        $item('#ownerComment').text = itemData.ownerComment;
      }
    });

    repeater.data = donations.map((donation) => ({
      ...donation,
      _id: donation.id,
    }));
  };

  return {
    pageReady: async () => {
      const donations = await fetchDonations(flowAPI);

      const mappedDonations = donations?.map((donation) => ({
        ...donation,
        _id: donation.id,
      }));
      displayFeed(mappedDonations);
    },
    exports: {},
  };
});
