import { DonationsApi } from '@wix/ambassador-donations-api/http';
import { Status } from '@wix/ambassador-donations-api/types';
import model from './model';
import {
  changePaymentStatus,
  createPayment,
  getDonationCause,
  createDonationCause,
} from '../../services/dontations-service';

export default model.createController(({ $w, $widget, flowAPI }) => {
  let amount = '';
  let activeButtonElement: any;

  const clearForm = () => {
    $w('#nameInput').value = '';
    $w('#contributorMessageInput').value = '';
    setActiveAmountButton('contributionAmountButton1');
    resetCustomAmount();
  };

  const handleDonationClick = async () => {
    const paymentInfo = {
      contributorName: $w('#nameInput').value,
      contributorComment: $w('#contributorMessageInput').value,
      amount,
    };
    const paymentId = await createPayment(flowAPI, paymentInfo);
    const paymentResult =
      // @ts-expect-error
      await flowAPI.controllerConfig.wixCodeApi.pay.startPayment(paymentId, {
        showThankYouPage: true,
      });

    if (paymentResult.status === 'Successful') {
      await changePaymentStatus(flowAPI, paymentId, Status.FINISHED);
      clearForm();
    }
  };

  const setActiveAmountButton = (buttonId) => {
    const buttonSelector = `#${buttonId}`;
    const buttonElement = $w(buttonSelector as any);
    resetCustomAmount();
    if (activeButtonElement && activeButtonElement?.id !== buttonId) {
      activeButtonElement.style.backgroundColor = '#181818';
    }

    activeButtonElement = buttonElement;
    activeButtonElement.style.backgroundColor = '#384AD3';

    setAmount({ buttonLabel: (activeButtonElement as any).label });
  };

  const resetActiveButton = () => {
    if (activeButtonElement) {
      activeButtonElement.style.backgroundColor = '#181818';
      activeButtonElement = null;
    }
  };

  const resetCustomAmount = () => {
    $w('#customContributionAmountInput').value = '';
  };

  const setAmount = ({
    buttonLabel,
    value,
  }: {
    buttonLabel?: string;
    value?: string;
  }) => {
    if (value) {
      amount = value;

      return;
    }

    if (buttonLabel) {
      const [, _amount] = buttonLabel.split(' ');
      amount = _amount;
    }
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      const contributionButtons = [
        '#contributionAmountButton1',
        '#contributionAmountButton2',
        '#contributionAmountButton3',
      ];
      contributionButtons.forEach((buttonId) => {
        $w(buttonId as any).onClick((event) =>
          setActiveAmountButton(event.target.id),
        );
      });

      $w('#customContributionAmountInput').onKeyPress(() => {
        resetActiveButton();
      });

      $w('#customContributionAmountInput').onChange((event) => {
        const value = event.target.value;
        setAmount({ value });

        if (!value) {
          setActiveAmountButton('contributionAmountButton1');
        }
      });

      setActiveAmountButton('contributionAmountButton1');
      $w('#donateButton').onClick(handleDonationClick);
    },
    exports: {},
  };
});
