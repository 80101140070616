
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation App/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation App/viewer.controller.ts';
            var controllerExport0 = _controllerExport0;
            

            
import wrapController1 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller1 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation form/viewer.controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation form/viewer.controller.ts';
            var controllerExport1 = _controllerExport1;
            

            
import wrapController2 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller2 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donations/viewer.controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donations/viewer.controller.ts';
            var controllerExport2 = _controllerExport2;
            

            
import wrapController3 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller3 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donor Feed/viewer.controller.ts';
            import * as _controllerExport3 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donor Feed/viewer.controller.ts';
            var controllerExport3 = _controllerExport3;
            

            
import wrapController4 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller4 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Progress bar/viewer.controller.ts';
            import * as _controllerExport4 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Progress bar/viewer.controller.ts';
            var controllerExport4 = _controllerExport4;
            

            
import wrapController5 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller5 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Repeater with items/viewer.controller.ts';
            import * as _controllerExport5 from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Repeater with items/viewer.controller.ts';
            var controllerExport5 = _controllerExport5;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = null;

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/cec612708f479ac4/packages/hack22-donations/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "hack22-donations",
    biConfig: null,
    appName: "Donations",
    appDefinitionId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation App/viewer.controller.ts",
          appName: "Donations",
          appDefinitionId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57",
          projectName: "hack22-donations",
          componentName: "Donation App",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-urqxv" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation form/viewer.controller.ts",
          appName: "Donations",
          appDefinitionId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57",
          projectName: "hack22-donations",
          componentName: "Donation form",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-ccarp" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donations/viewer.controller.ts",
          appName: "Donations",
          appDefinitionId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57",
          projectName: "hack22-donations",
          componentName: "Donations",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-kaeap" }, { method: controller3,
          wrap: wrapController3,
          exports: controllerExport3,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donor Feed/viewer.controller.ts",
          appName: "Donations",
          appDefinitionId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57",
          projectName: "hack22-donations",
          componentName: "Donor Feed",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-nqe6o" }, { method: controller4,
          wrap: wrapController4,
          exports: controllerExport4,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Progress bar/viewer.controller.ts",
          appName: "Donations",
          appDefinitionId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57",
          projectName: "hack22-donations",
          componentName: "Progress bar",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-x9tr9" }, { method: controller5,
          wrap: wrapController5,
          exports: controllerExport5,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Repeater with items/viewer.controller.ts",
          appName: "Donations",
          appDefinitionId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57",
          projectName: "hack22-donations",
          componentName: "Repeater with items",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-lk5qk" }],
    false);

    export const createControllers = _createControllers
