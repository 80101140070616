import { HttpClient } from '@wix/yoshi-flow-editor';
import { DonationsApi } from '@wix/ambassador-donations-api/http';
import { Status } from '@wix/ambassador-donations-api/types';

const BASE_URL = '_api/donations-api/';

export const fetchDonations = async (flowAPI) => {
  const httpClient = new HttpClient();
  const response = await DonationsApi(BASE_URL, {
    wixHttpClientInstance: httpClient,
  })
    .Donations()({
      Authorization: flowAPI.controllerConfig.appParams.instance,
    })
    .listDonationsFeed({ donationStatus: { status: Status.FINISHED } });

  return response.donations;
};

export const createPayment = async (
  flowAPI,
  { contributorName, contributorComment, amount },
) => {
  const httpClient = new HttpClient();
  const response = await DonationsApi(BASE_URL, {
    wixHttpClientInstance: httpClient,
  })
    .Donations()({
      Authorization: flowAPI.controllerConfig.appParams.instance,
    })
    .createDonation({
      donation: {
        payment: { currency: 'USD', amount },
        createdDate: new Date(),
        contributorComment,
        contributorName,
      },
    });

  return response.paymentId;
};

export const changePaymentStatus = async (flowAPI, paymentId, status) => {
  const httpClient = new HttpClient();
  const response = await DonationsApi(BASE_URL, {
    wixHttpClientInstance: httpClient,
  })
    .Donations()({
      Authorization: flowAPI.controllerConfig.appParams.instance,
    })
    .changeStatus({ paymentId, status });

  return response;
};

export const getDonationCause = async (flowAPI) => {
  const httpClient = new HttpClient();
  const response = await DonationsApi(BASE_URL, {
    wixHttpClientInstance: httpClient,
  })
    .DonationsCause()({
      Authorization: flowAPI.controllerConfig.appParams.instance,
    })
    .getDonationCause({});

  return response.donationCause;
};

export const createDonationCause = async (flowAPI) => {
  const httpClient = new HttpClient();
  const response = await DonationsApi(BASE_URL, {
    wixHttpClientInstance: httpClient,
  })
    .DonationsCause()({
      Authorization: flowAPI.controllerConfig.appParams.instance,
    })
    .createDonationCause({
      donationCause: { progressBar: { goal: '1000', progress: '' } },
    });

  return response.donationCause;
};
