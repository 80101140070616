import { createRtfFormatter } from '@wix/yoshi-flow-editor';

const rtfFormatter = createRtfFormatter('en', {} as any, {} as any);

export const formatDate = (date) => {
  const format = getTimeAgoFormat(date);

  if (format === 'timeRelative') {
    return isLessThanMinute(date)
      ? 'a few seconds ago'
      : rtfFormatter(new Date(date));
  }

  const options: any = timeOptions.formats.date[format];

  return new Intl.DateTimeFormat('en', options).format(new Date(date));
};

const weekAgo = () => new Date().getTime() - 1000 * 60 * 60 * 24 * 7;

const minuteAgo = () => new Date().getTime() - 1000 * 60;

export const isLessThanMinute = (time) =>
  new Date(time).getTime() > minuteAgo();

const isLessThanWeek = (time) => new Date(time).getTime() > weekAgo();

export const getTimeAgoFormat = (time) => {
  if (isLessThanWeek(time)) {
    return 'timeRelative';
  }
  return 'date';
};

export const timeOptions = {
  formats: {
    date: {
      date: { month: 'short', day: 'numeric' },
    },
  },
};
