import { Status } from '@wix/ambassador-donations-api/types';
import model from './model';
import {
  changePaymentStatus,
  createPayment,
} from '../../services/dontations-service';

export default model.createController(({ $w, $widget, flowAPI }) => {
  let amount;

  const handleDonationClick = async () => {
    const paymentInfo = {
      contributorName: $w('#nameInput').value,
      contributorComment: $w('#contributorMessageInput').value,
      amount,
    };
    const paymentId = await createPayment(flowAPI, paymentInfo);
    const paymentResult =
      // @ts-expect-error
      await flowAPI.controllerConfig.wixCodeApi.pay.startPayment(paymentId, {
        showThankYouPage: false,
      });

    if (paymentResult.status === 'Successful') {
      await changePaymentStatus(flowAPI, paymentId, Status.FINISHED);
    }
  };

  const setAmount = (event) => {
    console.log(event);
    amount = event.target.label.split(' ')[0];
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const contributionButtons = [
        '#contributionAmountButton1',
        '#contributionAmountButton2',
        '#contributionAmountButton3',
      ];
      contributionButtons.forEach((buttonId) => {
        $w(buttonId as any).onClick(setAmount);
      });

      $w('#donateButton').onClick(handleDonationClick);
    },
    exports: {},
  };
});
