import {
  createDonationCause,
  getDonationCause,
} from '../../services/dontations-service';
import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      try {
        const donationCause = await getDonationCause(flowAPI);
        const { progressBar } = donationCause as any;
        const progress = Number(progressBar.progress).toFixed(2);

        $w('#goalProgressBar').targetValue = +progressBar.goal;
        $w('#goalProgressBar').value = +progress;
        $w(
          '#goalTitle',
        ).text = `${progress}$ raised out of ${progressBar.goal} $ goal`;
      } catch (error) {
        await createDonationCause(flowAPI);
      }
    },
    exports: {},
  };
});
